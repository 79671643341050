import { inject, Injectable, signal } from '@angular/core';
import {
  GetOrderProductsResponse,
  OrderProduct,
} from '../../products/model/product.model';
import { HttpClient } from '@angular/common/http';

import { environment as env } from '../../../environments/environment';
import { catchError, map, Observable, of, tap } from 'rxjs';
import { PathStorage } from '../../utils/utils';;

@Injectable({
  providedIn: 'root',
})
export class CartService {
  
  cartProducts = signal<OrderProduct[]>([]);
  http = inject(HttpClient);

  subTotal = signal<number>(0);
  itemsInCartQnt = signal<number>(0);

  removeFromCart(id: number): void {
    this.cartProducts.update((prods) => prods.filter((prod) => prod.id !== id));
  }

  getProduct(id: number): OrderProduct {
    return this.cartProducts().find((c) => c.id === id)!;
  }

  getProductQuantity(id: number): number {
    return (this.cartProducts().find((c) => c.id === id)!).quantity;
  }

  addToCart(id: number) {
    if (id)
      this.setCartProductQuantity(id, 1)
        .pipe(
          tap(async (res) => {
            if (!res) return;
          }),
          catchError(async (error) => {
            console.log('Error ', error);

            return error;
          })
        )
        .subscribe();
  }

  clearCart() {
    this.deleteCart()
      .pipe(
        tap(() => {
          this.cartProducts.set([]);
          this.refreshTotal();
        }),
        catchError(async (error) => {
          console.log('Errore ', error);
          return error;
        })
      )
      .subscribe();
  }

  getCart(): Observable<GetOrderProductsResponse> {
    return this.http.get<GetOrderProductsResponse>(`${env.apiUrl}/cart/`);
  }


  refreshCart(): Promise<void> {
    return new Promise((resolve, reject) => {
      this.getCart()
        .pipe(
          tap((cartResponse) => {
            if (!cartResponse) return;
            let cartData = cartResponse.data;
            cartData.map(
              (product) => (product.imagePath = PathStorage + product.imagePath)
            );
            this.cartProducts.set(cartData);
            this.refreshTotal();
            resolve();
          }),
          catchError((error) => {
            console.log('Errore ', error);
            reject(error);
            return error;
          })
        )
        .subscribe();
    });
  }
  
  incrementProductQuantity(productId: number): Observable<GetOrderProductsResponse> {
    const productInCart = this.cartProducts().find(p => p.id == productId);
    const newQuantity = productInCart ? productInCart.quantity + 1 : 1;
    return this.setCartProductQuantity(productId, newQuantity);
  }

  setCartProductQuantity(
    productId: number,
    quantity: number
  ): Observable<GetOrderProductsResponse> {
    let prod: OrderProduct | undefined = this.cartProducts().find(
      (p) => p.id === productId
    );
    return this.http
      .put<GetOrderProductsResponse>(`${env.apiUrl}/cart/${productId}`, {
        quantity: quantity.toString(),
      })
      .pipe(
        map((res) => {
          if (quantity > 0) {
            prod = res.data[0];
          } else {
            this.cartProducts.update((prods) =>
              prods.filter((prod) => prod.id !== productId)
            );
            this.refreshTotal();
          }
          if (prod) {
            this.cartProducts.update((list) => {
              const listId = list.findIndex((p) => p.id == prod!.id);
              if (listId) {
                list[listId] = prod!;
              }

              return list;
            });
          }

          return res;
        }),
        catchError((error) => {
          console.log('error ', error);
          return of();
        })
      );
  }

  deleteCart() {
    return this.http.delete(`${env.apiUrl}/cart/`);
  }

  refreshTotal() {
    this.subTotal.update(() =>
      this.cartProducts().reduce(
        (acc, item) => acc + item.points * item.quantity,
        0
      )
    );
    this.itemsInCartQnt.update(() =>
      this.cartProducts().reduce((acc, item) => acc + item.quantity, 0)
    );
    if (this.cartProducts().length == 0) this.itemsInCartQnt.set(0);
  }

  getProductOnCart(id: number): number {
    return this.cartProducts().findIndex((p) => p.id === id);
  }
}
